import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchNowPlayingMovies } from "@store/movies/moviesActions";
import { useHistory } from "react-router-dom";
import { timeConvert } from "@helper/formatting.js";
import { withNamespaces } from "react-i18next";
import i18n from "../plugins/i18n";
import MovieHeader from "@components/partials/MovieHeader";
import GoBack from "@components/partials/GoBack";
import { globalConfigContext } from "@context/GlobalConfigContext";
import { Row, Container, Col, Spinner } from "react-bootstrap";
import CSpinner from "@components/partials/Spinner";
import ListMovies from "@components/ListMovies";
import gridView from "../assets/imgs/svgs/grid-view.svg";
import listView from "../assets/imgs/svgs/list-view.svg";
import { fetchDateAllShows } from "@store/movies/moviesActions";
import moment from "moment";
import movieDef from "@assets/imgs/movie-default-portrait.jpg";
import { getLangSpecificAttribute } from "../helper/Languages";

function Movies({ t }) {
  return (
    <div className="movie_listing_container">
      <MovieHeader t={t} />
      <MovieListings t={t} />
    </div>
  );
}

const MovieListings = ({ t }) => {
  const currentDate = moment().format("DD-MM-YYYY");
  const nextDate = moment().add(1, "days").format("DD-MM-YYYY");
  const movieListsLoader = useSelector(
    (state) => state.movies.movie_list_loader
  );
  const movieLists = useSelector((state) => state.movies.movie_list);

  const history = useHistory();
  const dispatch = useDispatch();
  const [display, setDisplay] = useState("grid");
  const [showSpinner, setShowSpinner] = useState(false);
  const global = useSelector((state) => state.global);
  const {
    cinema: { cinema_id },
    selected_language: { lang_id },
    dummy_image,
  } = global;
  useEffect(() => {
    dispatch(
      fetchNowPlayingMovies({
        cinema_id,
      })
    );
    dispatch(
      fetchDateAllShows({
        selectedDate: currentDate,
        cinema_id: cinema_id,
      })
    );
  }, [dispatch, cinema_id, currentDate]);

  const movies = useSelector((state) => state.movies.now_playing_movies);
  const moviesLoader = useSelector((state) => state.movies.movies_loader);

  const onClickGoBack = () => {
    history.push("/");
  };

  const onSelectFilter = (title, id) => {
    console.log(title, id);
    if (title == "format") {
      dispatch(
        fetchDateAllShows({
          selectedDate: currentDate,
          cinema_id: 3,
          format_id: id,
        })
      );
    }

    if (title == "rating") {
      dispatch(
        fetchDateAllShows({
          selectedDate: currentDate,
          cinema_id: 3,
          rating_id: id,
        })
      );
    }

    if (title == "refresh") {
      dispatch(
        fetchDateAllShows({
          selectedDate: currentDate,
          cinema_id: 3,
        })
      );
    }

    console.log(title);
  };
  const GridView = () => {
    return (
      <div>
        <h2 className="text-center pt-3 font-weight-bold f-blue">
          {moment({ currentDate }).format("dddd")}
        </h2>
        {movieListsLoader &&
          movieLists.map((movie, index) => {
            return (
              <div className="row content">
                <div className="col-md-1">
                  <img
                    className="img-fluid"
                    src={
                      getLangSpecificAttribute(
                        movie.MovieContent,
                        lang_id,
                        "artwork"
                      ) || dummy_image
                    }
                  />
                </div>
                <div className="col-md-2">
                  <h3 class="text-limit v3 fw-bold">
                    {movie.ss_start_show_time}
                  </h3>
                </div>
                <div className="col-md-8">
                  <h3 class="text-limit v3 fw-bold">
                    {getLangSpecificAttribute(
                      movie.MovieContent,
                      lang_id,
                      "mc_title"
                    )}
                  </h3>
                  <div class="middle-content filter-tags my-3">
                    <div>{movie.language_name}</div>
                    <div>{movie.movie_format}</div>
                  </div>
                  <div class="bottom-content">
                    {/* <div>{timeDifference(movie.ss_start_show_time, movie.ss_end_show_time)}
            </div> */}
                    <div>{movie.mrrdr_interval_time} mins</div>
                    {/* <div>Alterfreigabe 12/12</div> */}
                    <div class="seat-occupied">
                      <div>{movie.rating}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    // <div className="movie_listings">
    //   <div onClick={onClickGoBack} className="go_back">
    //     <div className="button_wrap">
    //       <div className="go_back_button"></div>
    //     </div>
    //     <h2 className="big_text">{t("GO BACK")}</h2>
    //   </div>
    //   <div className="all_movies">
    //     {movies.length > 0 ? (
    //       movies.map((m) => <MovieCard m={m} t={t} />)
    //     ) : (
    //       <div className="go_back">
    //         <h2 className="big_text">{t("No Movies Found")}</h2>
    //       </div>
    //     )}
    //   </div>
    // </div>
    <div>
      {/* <CSpinner active={showSpinner} /> */}
      <Container fluid>
        <div>
          <div className="movie_listings">
            <GoBack to="prev" />
            <div class="row grid-icons px-3 my-4">
              <div class="pr-4" onClick={() => setDisplay("grid")}>
                <img
                  className={
                    "btn-group pull-right " +
                    (display === "grid" ? "" : "low-opacity")
                  }
                  src={gridView}
                  alt="icon"
                />
              </div>
              <div onClick={() => setDisplay("list")}>
                <img
                  className={
                    "btn-group pull-right " +
                    (display === "list" ? "" : "low-opacity")
                  }
                  src={listView}
                  alt="icon"
                />
              </div>
            </div>
            {display == "grid" ? (
              <Row>
                {movies.length > 0
                  ? movies.map((m) => (
                      <MovieCard
                        m={m}
                        t={t}
                        key={m.movie_id}
                        lang_id={lang_id}
                        dummy_image={dummy_image}
                      />
                    ))
                  : !moviesLoader && (
                      <div className="go_back">
                        <h2 className="big_text">
                          {t("movie_listing.No Movies Found")}
                        </h2>
                      </div>
                    )}
                {moviesLoader && (
                  <div className="text-center w-100">
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner spinner2"
                    >
                      <span className="sr-only">{t("Loading")}</span>
                    </Spinner>
                    <br />
                    <h6>{t("movie_listing.Movies Loading")}</h6>
                  </div>
                )}
              </Row>
            ) : null}

            {display === "list" && <ListMovies />}
          </div>
        </div>
      </Container>
    </div>
  );
};
const MovieCard = ({ m, t, lang_id, dummy_image }) => {
  console.log("m :>> ", m);
  // const { arabic, setArabic } = useContext(globalConfigContext);
  const arabic = false;
  const history = useHistory();
  // movie id = md_id
  // movie id2 = actual movie_id
  const onClickBookTickets = (movie_id, movie_id2, language_id) => {
    history.push(`/movie-details/${movie_id}/${language_id}/${movie_id2}`);
  };
  return (
    // <div className="card_container">
    //   <div
    //     className="movie_pic"
    //     style={{
    //       backgroundImage: `url("${m.md_thumbnail_url}")`,
    //       backgroundSize: "cover",
    //       backgroundPosition: "50% 50%",
    //       backgroundRepeat: "no-repeat",
    //     }}
    //   ></div>
    //   <div className="movie_info">
    //     <div className="movie_name">{m.movie_title}</div>
    //     <div className="right_side">
    //       <div className="right_top">
    //         {m.rating} | {timeConvert(m.mrrdr_runtime)}
    //       </div>
    //       {/* <div className="right_bot">English, Arabic</div> */}
    //     </div>
    //   </div>
    //   <button
    //     onClick={() => onClickBookTickets(m.md_id, m.content_lang_id)}
    //     className="book_ticket"
    //   >
    //     {t("BOOK TICKETS")}
    //   </button>
    // </div>
    <Col
      xs={4}
      className="mt-4 mb-3 ml-col"
      onClick={() => onClickBookTickets(m.movie_id, m.md_id, m.content_lang_id)}
    >
      <div className="card_container">
        <div className="movie_pic">
          <img
            src={
              getLangSpecificAttribute(m.MovieContent, lang_id, "artwork") ||
              dummy_image
            }
            alt=""
          />
        </div>
        <div className="movie_info">
          {/* <div className="movie_name">{m.movie_title}</div>
					<div className="right_side">
						<div className="right_top">
							{m.rating} | {timeConvert(m.run_time)}
						</div>
						<div className="right_bot">English, Arabic</div> 
					</div> */}
          <Col xs={6} className="px-2">
            <span className="text-limit v3 fw-bold frs-16">
              {arabic
                ? m.movie_title_ar
                : getLangSpecificAttribute(m.MovieContent, lang_id, "mc_title")}
            </span>
            {/* For Language? */}
            <span className="kumbh fw-bold frs-12 text-truncate">
              {m.lang_name}
            </span>
          </Col>
          <Col
            xs={6}
            className="px-2 d-flex justify-content-end align-items-end"
          >
            <div className="group px-2">
              <span
                className={`${
                  arabic ? "border-left pl-1" : "border-right pr-1"
                }`}
              >
                {m.rating}{" "}
              </span>
              <span className={`${arabic ? "pr-1" : "pl-1"}`}>
                {timeConvert(m.mrrdr_runtime)}
              </span>
            </div>
          </Col>
        </div>
        <p
          onClick={() =>
            onClickBookTickets(m.movie_id, m.md_id, m.content_lang_id)
          }
          className="blue-btn w-100 frs-16"
        >
          {t("movie_listing.BOOK TICKETS")}
        </p>
      </div>
    </Col>
  );
};
export default withNamespaces()(Movies);
