import Api from "./Api";
import { createQueryString } from "./ApiHelper";

export default {
  GetAllShows(payload) {
    const query = createQueryString(payload);
    return Api().get(`/api/external/movies?${query}`, payload);
  },
  GetNowPlayingMovies() {
    return Api().get(
      `/api/external/movies-nowShowing-comingSoon?flag=NowShowing`
    );
  },
  GetMovieDetails(payload) {
    return Api().get(
      `/api/external/movie-details/${payload.movie_id}/${
        payload.content_lang_id
      }${payload.md_id ? `?md_id=${payload.md_id}` : ""}`
    );
  },
  GetShowTimes(payload) {
    return Api().post(`/api/external/schedule`, payload);
  },
  GetShowDates(payload) {
    let query = createQueryString(payload);
    return Api().get(`/api/external/show-dates/${query}`);
  },
  GetMovieFilters(payload) {
    return Api().post("/api/external/movie-filters", payload);
  },
  GetMovieDates(payload) {
    return Api().post("/api/external/movie-dates", payload);
  },
  GetAppAllShows(payload) {
   return Api().post("/api/external/getappAllShows", payload);
  }
};
