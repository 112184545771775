import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMovieDetails,
  fetchShowTimes,
  fetchMovieDates,
  fetchNowPlayingMovies,
  fetchMovieFormats,
  setSelectedSession,
} from "@store/movies/moviesActions";
import { timeConvert } from "@helper/formatting.js";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import "./movie.css";
import MovieHeader from "@components/partials/MovieHeader";
// import { globalConfigContext } from '@context/GlobalConfigContext';
import CSpinner from "@components/partials/Spinner";
import { withNamespaces } from "react-i18next";
import GoBack from "@components/partials/GoBack";
import Slider from "react-slick";
import i18n from "../plugins/i18n";
import { Row, Container, Col } from "react-bootstrap";
import groupBy from "lodash/groupBy";
import { getLangSpecificAttribute } from "../helper/Languages";
const MovieDetailsCard = (props) => {
  // const { arabic, setArabic } = useContext(globalConfigContext);
  const arabic = "false";
  const { movie_id, content_lang_id, movie_id2 } = this.props.match.params;
  return (
    // <div className="main_container">
    //   <MovieHeader />
    //   <div className="goback_area">
    //     <GoBack t={props.t} />
    //   </div>
    //   <MovieDetails
    //     content_lang_id={content_lang_id}
    //     movie_id={movie_id}
    //     t={props.t}
    //   />
    // </div>
    <></>
  );
};

const MovieDetails = ({ t, match }) => {
  const { movie_id, content_lang_id, movie_id2 } = match.params;
  const sliderRef = React.createRef();

  // const { arabic, setArabic } = useContext(globalConfigContext);
  const arabic = "false";
  const dispatch = useDispatch();
  const [slideArrow, setSlideArrow] = useState("");
  const chooseDateSlider = {
    arrows: true,
    infinite: false,
    dots: true,
    speed: 800,
    slidesToShow: 9,
    slidesToScroll: 3,
    afterChange: (current, index) => {
      if (show_dates.length - chooseDateSlider.slidesToShow == current) {
        setSlideArrow("arrow-disabled");
      } else {
        setSlideArrow("");
      }
    },
  };

  let days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const [currentShowDate, setCurrentDate] = useState(null);
  const onSelectDate = (date, index) => {
    setSelectDate(index);
    setCurrentDate(moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"));
  };
  const [selectdate, setSelectDate] = useState(0);
  const [modifiedShowTimes, setModifiedShowTimes] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => window.scrollTo(0, 0), []);
  const brijMovieDetails = useSelector((state) => state.movies.movie_details);
  const movies = useSelector((state) => state.movies.now_playing_movies);
  console.log("movies", movie_id);
  const movieDetails = useSelector((state) => state.movies.movie_details);
  const ShowTimes = useSelector((state) => state.movies.show_times);
  const ShowTimeLoader = useSelector((state) => state.movies.show_times_loader);
  const show_dates = useSelector(
    (state) => state.movies.current_movie_show_dates
  );
  const ShowDateLoader = useSelector(
    (state) => state.movies.dates_slider_loader
  );
  const movieFormats = useSelector((state) => state.movies.movieFormats);
  useEffect(() => {
    dispatch(
      fetchMovieDetails({
        movie_id: movie_id,
        content_lang_id,
        md_id: movie_id2,
      })
    );
    dispatch(fetchMovieFormats({ movie_id: movie_id }));
    dispatch(
      fetchMovieDates({ movie_id: movie_id, cinema_id, md_id: movie_id2 })
    );
    // setSlideArrow("arrows-3 slick-track-no-margin");
  }, []);
  const global = useSelector((state) => state.global);
  const {
    cinema: { cinema_id },
    selected_language: { lang_name, lang_id },
    dummy_image,
  } = global;
  let getLanguageSpecificMovieDetails = useRef({});
  const languageSpecificMovieFunction = () => {
    switch (lang_name) {
      case "English":
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === "English");
        break;
      case "German":
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === "German");
        break;
      case "Italian":
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === "Italian");
        break;
      case "French":
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === "French");
        break;
      case "Hindi":
        getLanguageSpecificMovieDetails.current =
          movieDetails?.altMovieContent?.find((c) => c.lang_name === "Hindi");
        break;
      default:
        getLanguageSpecificMovieDetails.current = movieDetails;
    }
    if (getLanguageSpecificMovieDetails.current === undefined) {
      getLanguageSpecificMovieDetails.current = movieDetails;
    }
  };

  useEffect(() => {
    languageSpecificMovieFunction();
  }, [lang_name]);

  useEffect(() => {
    if (show_dates && show_dates.length > 0) {
      setCurrentDate(show_dates[0]);
    }
  }, [show_dates]);

  useEffect(() => {
    if (currentShowDate && movieFormats?.length > 0) {
      dispatch(
        fetchShowTimes({
          date: currentShowDate,
          cinema_id,
          movieFormats,
          md_id: movie_id2,
        })
      );
    }
  }, [movieFormats, currentShowDate]);

  useEffect(() => {
    if (ShowTimes && ShowTimes.length > 0) {
      let groupedShowTimes = groupBy(ShowTimes, "lang_id");
      setModifiedShowTimes(groupedShowTimes);
      console.log("modifiedShowtimes", groupedShowTimes);
    } else {
      setModifiedShowTimes(null);
    }
  }, [ShowTimes]);

  // useEffect(() => {
  //     let newShowTimes = [];
  //     for (let i = 0; i < ShowTimes.length; i++) {
  //       let newCinemaObj = { CinemaId: ShowTimes[i].cinema_id };
  //       newShowTimes.push(newCinemaObj);
  //       if (
  //         ShowTimes[i].Screens &&
  //         ShowTimes[i].Screens.length > 0 &&
  //         ShowTimes[i].Screens[0].ShowTimes.length > 0
  //       ) {
  //         newCinemaObj.CinemaName =
  //           ShowTimes[i].Screens[0].ShowTimes[0].CinemaName;
  //         newCinemaObj.CinemaAddress =
  //           ShowTimes[i].Screens[0].ShowTimes[0].cinema_address;
  //       }

  //       newCinemaObj.Shows = {};
  //       for (let screen of ShowTimes[i].Screens) {
  //         for (let showTime of screen.ShowTimes) {
  //           if (showTime.SessionAttribute) {
  //             newCinemaObj.Shows[showTime.SessionAttribute]
  //               ? newCinemaObj.Shows[showTime.SessionAttribute].push(showTime)
  //               : (newCinemaObj.Shows[showTime.SessionAttribute] = [showTime]);
  //           } else {
  //             newCinemaObj.Shows["Others"]
  //               ? newCinemaObj.Shows["Others"].push(showTime)
  //               : (newCinemaObj.Shows["Others"] = [showTime]);
  //           }
  //         }
  //       }
  //     }

  //     setModifiedShowTimes(newShowTimes);

  // }, [ShowTimes]);

  return (
    // <div className="movieDetails_mainContainer">
    //   <div className="movieDetails_container">
    //     <div className="thumbnail">
    //       <img
    //         className="banner"
    //         src={movieDetails.md_thumbnail_url}
    //         alt={movieDetails.movie_title}
    //       />
    //     </div>
    //     <div className="details_container">
    //       <div className="details_header">
    //         <div className="header_left">
    //           <h1>{movieDetails.movie_title}</h1>
    //           {/* <p>English, Arabic</p> */}
    //         </div>
    //         <div className="header_right">
    //           <div>
    //             <p>{movieDetails.rating}</p>
    //             <p className="time">
    //               {timeConvert(movieDetails.mrrdr_runtime)}
    //             </p>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="details_body">
    //         <p className="title">{t("Synopsis")}</p>
    //         <p className="content">{movieDetails.mc_plot}</p>
    //         {/* <p className="title">Cast</p>
    // 				<p className="content">Will Smith, Martin Lawrence</p>
    // 				<p className="title">Directed By</p>
    // 				<p className="content">Michael Bay</p> */}
    //       </div>
    //     </div>
    //   </div>

    //   <div className="movieFeatures_container">
    //     <div className="features_header">
    //       <div className="featureHeader_left">
    //         <p>{t("Date")}</p>
    //         <h2>{moment(currentShowDate, "DD-MM-YYYY").format("LL")}</h2>
    //       </div>
    //       {/* <div className="featureHeader_right">
    // 				<p>Choose Language</p>
    // 				<div className="featureHeader_right_buttons">
    // 					<button className="active_language">ENGLISH</button>
    // 					<button className="language_button">ARABIC</button>
    // 				</div>
    // 			</div> */}
    //     </div>

    //     <div className="features_body">
    //       <p>{t("Choose Show Time")}</p>
    //       {ShowTimes && ShowTimes.length > 0
    //         ? ShowTimes.map((show, cinemaIndex) => (
    //             // Object.keys(
    //             //   cinema.Shows
    //             // ).map((showtypeKey, showtypeKeyIndex) => (

    //             // ))
    //             <ShowTimings
    //               key={show.ss_id}
    //               plan={show.ss_id}
    //               movie_id={movie_id}
    //               timing={show.ss_start_show_time}
    //               session={show.ss_id}
    //               screen={show.screen_id}
    //             />
    //           ))
    //         : null}
    //     </div>
    //   </div>
    // </div>
    <>
      <div>
        <MovieHeader changeLanguageFunction={languageSpecificMovieFunction()} />
        <Container fluid>
          <div>
            <div className="main_container">
              <GoBack t={t} to="prev" force={true} />
              <div className="movieDetails_mainContainer">
                <CSpinner active={showSpinner} />
                <Row className="w-100">
                  <Col className="px-0">
                    <div className="movieDetails_container">
                      <Col xs={4} className={`${arabic ? "pr-0" : "pl-0"}`}>
                        <div className="thumbnail">
                          <img
                            className="banner"
                            src={
                              getLangSpecificAttribute(
                                movieDetails.languageSpecificImages,
                                lang_id,
                                "artwork"
                              ) || dummy_image
                            }
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col xs={8} className={`${arabic ? "pl-0" : "pr-0"}`}>
                        <div className="details_container">
                          <div className="details_header">
                            <div className="header_left">
                              <h1>
                                {" "}
                                {/* {
                                  getLanguageSpecificMovieDetails?.current
                                    ?.mc_title
                                } */}
                                {getLangSpecificAttribute(
                                  movieDetails.languageSpecificImages,
                                  lang_id,
                                  "mc_title"
                                )}
                              </h1>
                              <p className="frs-20">
                                {movieDetails?.lang_name}
                              </p>
                            </div>
                            {/* <div className="header_right">
                      <div>
                          <p>{movieDetails?.rating}</p>
                          <p className="time">{timeConvert(movieDetails?.mrrdr_runtime)}</p>
                      </div>
                  </div> */}
                            <div className="group px-2">
                              <span
                                className={`${
                                  !arabic
                                    ? "border-left pl-2"
                                    : "border-right pr-2"
                                }`}
                              >
                                {movieDetails?.rating}
                              </span>
                              <span className={`${!arabic ? "pr-2" : "pl-2"}`}>
                                {timeConvert(movieDetails?.mrrdr_runtime)}
                              </span>
                            </div>
                          </div>

                          <div className="details_body mt-auto">
                            <p className="title">
                              {t("movie_details.Synopsis")}
                            </p>
                            <p className="content text-limit">
                              {
                                getLanguageSpecificMovieDetails?.current
                                  ?.mc_plot
                              }
                            </p>
                            <p className="title mt-auto">
                              {t("movie_details.Cast")}
                            </p>
                            {/* <p className="content">
              {arabic
                ? movieDetails?.movie_cast_ar
                : movieDetails?.movie_cast_crew}
            </p> */}
                            {/* <p className="title">Directed By</p> */}
                            <p className="content">
                              {brijMovieDetails?.castCrewDetails?.map(
                                (cast, index) => (
                                  <div key={index}>
                                    {cast.crew_title} -{" "}
                                    {cast?.Crew?.map((crew, index2) => (
                                      <div
                                        key={index2}
                                        style={{ display: "inline-block" }}
                                      >
                                        <p>
                                          {"  "}
                                          {crew.mcc_cast_name}
                                          {index2 !== cast.Crew.length - 1
                                            ? `, `
                                            : ""}
                                          &nbsp;
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>

                <Row className="w-100">
                  <Col className="movieFeatures_container">
                    <p className="date-heading">
                      {" "}
                      {t("movie_details.Choose Date")}
                    </p>
                    <div className="date-slider">
                      {show_dates?.length > 0 && (
                        <Slider
                          ref={sliderRef}
                          {...chooseDateSlider}
                          className={
                            "arrows-3 slick-track-no-margin " + slideArrow
                          }
                        >
                          {show_dates?.length > 0 &&
                            show_dates.map((day, index) => (
                              <div
                                key={index}
                                className={
                                  index == selectdate
                                    ? "date-box active"
                                    : "date-box"
                                }
                                onClick={() => onSelectDate(day, index)}
                              >
                                <p className="month-name">
                                  {moment(day, "DD-MM-YYYY").format("MMM")}
                                </p>
                                <p className="date-no">
                                  {moment(day, "DD-MM-YYYY").format("D")}
                                </p>
                                <p className="day-name">
                                  {moment(day, "DD-MM-YYYY").format("ddd")}
                                </p>
                              </div>
                            ))}
                        </Slider>
                      )}
                      {ShowDateLoader && (
                        <div className="text-center">
                          <div>
                            <Spinner
                              animation="border"
                              role="status"
                              className="spinner"
                            >
                              <span className="sr-only">{t("Loading")}</span>
                            </Spinner>
                          </div>
                          <br />
                          <h6>{t("movie_details.loading_dates")}</h6>
                        </div>
                      )}
                    </div>
                    <div className="features_header">
                      <div className="featureHeader_left">
                        <h2>
                          {moment(currentShowDate, "DD-MM-YYYY").format("LL")}
                        </h2>
                      </div>
                      {/* <div className="featureHeader_right">
                      <p>{t('movie_details.Choose Language')}</p>
                      <div className="featureHeader_right_buttons">
                          <button className="language_button active">{t('movie_details.ENGLISH')}</button>
                          <button className="language_button">{t('movie_details.ARABIC')}</button>
                      </div>
                  </div> */}
                    </div>

                    <div className="features_body">
                      <p className="pb-2">
                        {t("movie_details.Choose Show Time")}
                      </p>
                      {ShowTimeLoader ? (
                        <div className="text-center">
                          <div>
                            <Spinner
                              animation="border"
                              role="status"
                              className="spinner"
                            >
                              <span className="sr-only">{t("Loading")}</span>
                            </Spinner>
                          </div>
                          <br />
                          <h6>{t("movie_details.loading_time")}</h6>
                        </div>
                      ) : (
                        modifiedShowTimes &&
                        Object.keys(modifiedShowTimes).length > 0 &&
                        Object.keys(modifiedShowTimes).map((lang_id) => (
                          <div>
                            <h5 className="mt-2">
                              {modifiedShowTimes[lang_id][0].language_name}
                            </h5>
                            {modifiedShowTimes[lang_id].map(
                              (show, cinemaIndex) => (
                                <ShowTimings
                                  key={show.ss_id}
                                  plan={show.ss_id}
                                  movie_id={show.movie_details_id}
                                  timing={show.ss_start_show_time}
                                  session={show.ss_id}
                                  screen={show.screen_id}
                                  screen_name={show.screen_name}
                                  movie_format={show.movie_format}
                                  allShow={show}
                                />
                              )
                            )}
                          </div>
                        ))
                      )}
                      {/* {ShowTimes && ShowTimes.length > 0
                        ? ShowTimes.map((show, cinemaIndex) => (
                            // Object.keys(
                            //   cinema.Shows
                            // ).map((showtypeKey, showtypeKeyIndex) => (

                            // ))
                            <ShowTimings
                              key={show.ss_id}
                              plan={show.ss_id}
                              movie_id={movie_id}
                              timing={show.ss_start_show_time}
                              session={show.ss_id}
                              screen={show.screen_id}
                              screen_name={show.screen_name}
                              movie_format={show.movie_format}
                            />
                          ))
                        : null} */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const ShowTimings = ({
  screen,
  session,
  plan,
  movie_id,
  timing,
  screen_name,
  movie_format,
  allShow,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onClickShowTime = (SessionId, screen_id) => {
    history.push(`/seat-layout/${movie_id}/${SessionId}/${screen_id}`);
    dispatch(setSelectedSession(allShow));
  };
  let start_show_time = moment(
    `${allShow.ss_start_date} ${allShow.ss_start_show_time}`,
    "YYYY-MM-DD HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");
  let curent_time = moment().format("YYYY-MM-DD HH:mm:ss");
  let checkShowExist = moment(start_show_time).isBefore(moment(curent_time));
  console.log(curent_time, curent_time, checkShowExist);
  return (
    // <div className="showTimings">
    //   {/* <h1>{plan}</h1> */}
    //   <div className="time_slots">
    //     {/* {timings.map((t, index) => (

    //     ))} */}
    //     <button
    //       key={session}
    //       onClick={() => onClickShowTime(session, screen)}
    //       className="chooseTime_button"
    //     >
    //       {moment(timing, "HH:mm").format("HH:mm")}
    //     </button>
    //   </div>
    // </div>
    <div className="showTimings">
      {/* <h1>{plan}</h1> */}
      <div className="screen_name mb-2 text-center" style={{ width: "150px" }}>
        {screen_name}
        {" -"}
        <span class="formatbtn">{movie_format}</span>
      </div>
      <div className="time_slots">
        {/* {timings.map((t, index) => ( */}
        <button
          key={session}
          onClick={() => onClickShowTime(session, screen)}
          className="chooseTime_button"
          style={{
            background: checkShowExist ? "grey" : "",
            pointerEvents: checkShowExist ? "none" : "",
          }}
        >
          {moment(timing, "HH:mm").format("HH:mm")}
        </button>
        {/* ))} */}
      </div>
    </div>
  );
};
export default withNamespaces()(MovieDetails);
