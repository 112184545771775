import moment from "moment";

export const languages = {
  english: "en",
  german: "de",
  french: "fr",
  italian: "it",
};

export const timeDifference = (a,b) => {
  var startTime = moment(a, "HH:mm");
  var endTime = moment(b, "HH:mm");

  console.log(startTime, endTime);

  var duration = moment.duration(endTime.diff(startTime));
  return duration.asMinutes();
  
  // return duration;
}


export const timeConvert = (n) => {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + "h" + rminutes + "m";
};

export const getYoutubeVideoCode = (link) => {
  if (!link) {
    return "";
  }

  let code = null;
  if (link.indexOf("youtu.be") !== -1) {
    let res = link.split("/");
    code = res[3];
  } else {
    let res = link.split("=");
    code = res[1];
  }
  return code;
};

export const isImageURLNull = (url) => {
  if (url && (url.endsWith("null") || url.endsWith("undefined"))) {
    return true;
  }
  return false;
};

export function titleCase(word) {
  if (!word) return;
  var sentence = word.toLowerCase().split(" ");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(" ");
}

export const formatYuppErrors = (errs) => {
  return errs
    ? Object.assign({}, ...errs.inner.map((obj) => ({ [obj.path]: obj })))
    : null;
};

export const translateSessionAttribute = (arabic, sessionAttribute) => {
  if (!sessionAttribute) return "";
  if (!arabic) return titleCase(sessionAttribute);

  const translations = {
    premium: "بريميوم",
    macro: "دقيق",
    junior: "نجارة",
  };

  return translations[sessionAttribute.toLowerCase()]
    ? translations[sessionAttribute.toLowerCase()]
    : "";
};

export const getLangISOCode = (lang) => {
  if (!lang) {
    return "en";
  }

  if (languages[lang.toLowerCase()]) {
    return languages[lang.toLowerCase()];
  } else {
    return "en";
  }
};
