export const FETCH_COMING_SOON_MOVIES = "FETCH_COMING_SOON_MOVIES";
export const FETCH_COMING_SOON_ERROR = "FETCH_COMING_SOON_ERROR";
export const FETCH_NOW_PLAYING_MOVIES = "FETCH_NOW_PLAYING_MOVIES";
export const FETCH_NOW_PLAYING_ERROR = "FETCH_NOW_PLAYING_ERROR";
export const FETCH_BANNERS = "FETCH_BANNERS";
export const FETCH_BANNERS_ERROR = "FETCH_BANNERS_ERROR";
export const FETCH_MOVIE_DETAILS = "FETCH_MOVIE_DETAILS";
export const FETCH_MOVIE_DETAILS_ERROR = "FETCH_MOVIE_DETAILS_ERROR";
export const FETCH_SHOW_TIMES = "FETCH_SHOW_TIMES";
export const FETCH_SHOW_TIMES_ERROR = "FETCH_SHOW_TIMES_ERROR";
export const FETCH_CURRENT_MOVIE_SHOW_DATES = "FETCH_CURRENT_MOVIE_SHOW_DATES";
export const SET_CURRENT_MOVIE_SHOW_DATES = "SET_CURRENT_MOVIE_SHOW_DATES";
export const SET_MOVIE_DETAILS_LOADER = "SET_MOVIE_DETAILS_LOADER";
export const SET_SHOW_TIMES_LOADER = "SET_SHOW_TIMES_LOADER";
export const CLEAR_MOVIE_DETAILS_ERROR = "CLEAR_MOVIE_DETAILS_ERROR";
export const SET_MOVIE_FORMATS = "SET_MOVIE_FORMATS";
export const SET_MOVIES_LOADER = "SET_MOVIES_LOADER";
export const SET_SHOW_DATE_LOADER = "SET_SHOW_DATE_LOADER";
export const FETCH_DATE_ALL_SHOWS = "FETCH_DATE_ALL_SHOWS";
export const FETCH_DATE_ALL_SHOWS_LOADING = "FETCH_DATE_ALL_SHOWS_LOADING";
export const SET_SELECTED_SESSION = "SET_SELECTED_SESSION";
