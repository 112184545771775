import React, { useEffect } from "react";
import { Provider } from "react-redux";
//import store
import store from "./store/index";
// import router
import Router from "./Router";
//import i18n
import "./plugins/i18n";
//import compoents
// import Header from "./components/partials/Header";
//CSS Imports
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchGlobalSettings } from "./store/global/globalActions";
import { useDispatch, useSelector } from "react-redux";

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <Router />
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        limit={1}
      />
    </Provider>
  );
};

export default App;
