export const API_VERSION = "v1.0";
export const SOCKET_URL = "ws://localhost:5000/messages";
export const COM_PORT = "COM_PORT";
export const IP_ADDRESS = "IP_ADDRESS";
export const PORT = "PORT";

export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const BING_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImE2MTczODlhLWVjMjMtNDczMS1hMDM5LTI1ZWE5NTgxNTAyYyIsImlhdCI6MTYwNzMzNTU5MiwiZXhwIjoxNjA5OTI3NTkyfQ.L8u4KdN5gevTPP1YK5kDurg1ZitEEwcZg1JPJ0Y6ekI`;
export const SUPER_ADMIN_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjUwZmQ1YjVkLTZiZDMtNDY1MS05MTNmLWFjZDAyZjY1MDJkYSIsImlhdCI6MTYwNzMzMTMyMSwiZXhwIjoxNjA5OTIzMzIxfQ.hDjFhvxX_1YORVHw3C-PEPc3XxIiKJ-SSzkRv-vtwO8`;
export const IMACULIX3_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImVlOGZlM2I5LTdmZDgtNDE1ZC1hNTFhLWUxZGNiZjk2ZTFmYiIsImlhdCI6MTYyMzA3MDkwMCwiZXhwIjoxNjI1NjYyOTAwfQ.Nut3NVuZl2PuCOQtCo8ylGLpQEuANymw8RDqgeXQf_8`;

export const TOKEN = IMACULIX3_TOKEN;
const HOST_URL = `brij-kiosk-imaculix-3.brij.tech`;

const LIVEBASEURL = `https://cinematic-server-imaculix-3.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;

export const BASEURL =  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
