import {
  FETCH_COMING_SOON_MOVIES,
  FETCH_COMING_SOON_ERROR,
  FETCH_NOW_PLAYING_MOVIES,
  FETCH_NOW_PLAYING_ERROR,
  FETCH_BANNERS,
  FETCH_BANNERS_ERROR,
  FETCH_MOVIE_DETAILS,
  FETCH_MOVIE_DETAILS_ERROR,
  FETCH_SHOW_TIMES,
  FETCH_SHOW_TIMES_ERROR,
  FETCH_CURRENT_MOVIE_SHOW_DATES,
  SET_MOVIE_DETAILS_LOADER,
  SET_SHOW_TIMES_LOADER,
  CLEAR_MOVIE_DETAILS_ERROR,
  SET_CURRENT_MOVIE_SHOW_DATES,
  SET_MOVIE_FORMATS,
  SET_MOVIES_LOADER,
  SET_SHOW_DATE_LOADER,
  FETCH_DATE_ALL_SHOWS,
  FETCH_DATE_ALL_SHOWS_LOADING,
  SET_SELECTED_SESSION,
} from "./moviesTypes";

const initialState = {
  now_playing_movies: [],
  now_playing_error: null,
  movie_details: [],
  movie_list: null,
  movie_list_loader: false,
  movie_details_error: null,
  show_times: [],
  show_times_error: null,
  current_movie_show_dates: null,
  show_times_loader: false,
  movie_details_loader: false,
  movieFormats: [],
  movies_loader: false,
  dates_slider_loader: false,
  selected_session: null,
};

const moviesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOVIE_FORMATS:
      return {
        ...state,
        movieFormats: action.payload,
      };
    case FETCH_NOW_PLAYING_MOVIES:
      return {
        ...state,
        now_playing_movies: action.payload,
        movies_loader: false,
        error: action.payload,
      };
    case FETCH_NOW_PLAYING_ERROR:
      return {
        ...state,
        now_playing_movies: [],
        movies_loader: false,
        now_playing_error: action.payload,
      };
    case FETCH_MOVIE_DETAILS:
      return {
        ...state,
        movie_details: action.payload,
        movie_details_loader: false,
      };
    case FETCH_MOVIE_DETAILS_ERROR:
      return {
        ...state,
        movie_details_error: action.payload,
        movie_details_loader: false,
      };
    case FETCH_DATE_ALL_SHOWS:
      return {
        ...state,
        movie_list: action.payload,
      };
    case FETCH_DATE_ALL_SHOWS_LOADING: {
      return {
        ...state,
        movie_list_loader: action.payload,
      };
    }
    case SET_SELECTED_SESSION: {
      return {
        ...state,
        selected_session: action.payload,
      };
    }
    case FETCH_SHOW_TIMES:
      return {
        ...state,
        show_times: action.payload,
        show_times_loader: false,
      };
    case FETCH_SHOW_TIMES_ERROR:
      return {
        ...state,
        show_times_error: action.payload,
        show_times_loader: false,
      };
    case FETCH_CURRENT_MOVIE_SHOW_DATES:
      return {
        ...state,
        current_movie_show_dates: [],
      };
    case SET_SHOW_DATE_LOADER:
      return {
        ...state,
        dates_slider_loader: true,
      };
    case SET_CURRENT_MOVIE_SHOW_DATES:
      return {
        ...state,
        current_movie_show_dates: action.payload,
        dates_slider_loader: false,
      };
    case SET_SHOW_TIMES_LOADER:
      return {
        ...state,
        show_times_loader: action.payload,
        show_times: [],
      };
    case SET_MOVIES_LOADER:
      return {
        ...state,
        movies_loader: action.payload,
      };
    case SET_MOVIE_DETAILS_LOADER:
      return {
        ...state,
        movie_details_loader: action.payload,
      };
    case CLEAR_MOVIE_DETAILS_ERROR:
      return {
        ...state,
        movie_details_error: null,
      };
    default:
      return state;
  }
};

export default moviesReducer;
