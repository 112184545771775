import {
  FETCH_NOW_PLAYING_MOVIES,
  FETCH_NOW_PLAYING_ERROR,
  FETCH_BANNERS,
  FETCH_BANNERS_ERROR,
  FETCH_MOVIE_DETAILS,
  FETCH_MOVIE_DETAILS_ERROR,
  FETCH_SHOW_TIMES,
  FETCH_SHOW_TIMES_ERROR,
  FETCH_CURRENT_MOVIE_SHOW_DATES,
  SET_MOVIE_DETAILS_LOADER,
  SET_SHOW_TIMES_LOADER,
  CLEAR_MOVIE_DETAILS_ERROR,
  SET_CURRENT_MOVIE_SHOW_DATES,
  SET_MOVIE_FORMATS,
  SET_MOVIES_LOADER,
  SET_SHOW_DATE_LOADER,
  FETCH_DATE_ALL_SHOWS,
  FETCH_DATE_ALL_SHOWS_LOADING,
  SET_SELECTED_SESSION,
} from "./moviesTypes";
import MovieService from "@apiService/MovieService";
import moment from "moment";
import _ from "lodash";

const fetchNowPlayingSuccess = (data) => {
  return {
    type: FETCH_NOW_PLAYING_MOVIES,
    payload: data,
  };
};

const fetchNowPlayingError = (err) => {
  return {
    type: FETCH_NOW_PLAYING_ERROR,
    payload: err,
  };
};

const fetchMovieDetailsSuccess = (data) => {
  return {
    type: FETCH_MOVIE_DETAILS,
    payload: data,
  };
};

const fetchMovieDetailsError = (error) => {
  return {
    type: FETCH_MOVIE_DETAILS_ERROR,
    payload: error,
  };
};

const fetchShowTimeSuccess = (data) => {
  return {
    type: FETCH_SHOW_TIMES,
    payload: data,
  };
};

const fetchShowTimeError = (error) => {
  return {
    type: FETCH_SHOW_TIMES_ERROR,
    payload: error,
  };
};

const fetchCurrentMovieShowDates = (payload) => ({
  type: SET_CURRENT_MOVIE_SHOW_DATES,
  payload,
});

const setShowTimesLoader = (payload) => ({
  type: SET_SHOW_TIMES_LOADER,
  payload,
});

const setCurrentShowLoader = (payload) => ({
  type: SET_SHOW_DATE_LOADER,
  payload,
});

const setMoviesLoader = (payload) => ({
  type: SET_MOVIES_LOADER,
  payload,
});

const setMovieDetailsLoader = (payload) => ({
  type: SET_MOVIE_DETAILS_LOADER,
  payload,
});

export const clearMovieDetailsError = (payload) => ({
  type: CLEAR_MOVIE_DETAILS_ERROR,
});

export const setAllShowsLoading = (payload) => ({
  type: FETCH_DATE_ALL_SHOWS_LOADING,
  payload,
});

export const setSelectedSession = (payload) => ({
  type: SET_SELECTED_SESSION,
  payload,
});

export const fetchNowPlayingMovies = ({ cinema_id }) => {
  return async function (dispatch, getState) {
    const {
      global: {
        selected_language: { lang_id },
      },
    } = getState();
    dispatch(setMoviesLoader(true));
    try {
      const payload = {
        aggregator_cinema_id: cinema_id,
        isScheduled: true,
        lang_id,
      };
      // date: moment().format("DD-MM-YYYY"),
      const { data } = await MovieService.GetAllShows(payload);
      if (data.status && data.Records.length > 0) {
        const key = "movie_id";
        let uniqueMovies = [
          ...new Map(data.Records.map((item) => [item[key], item])).values(),
        ];
        uniqueMovies.sort((a, b) =>
          a.movie_title !== b.movie_title
            ? a.movie_title < b.movie_title
              ? -1
              : 1
            : 0
        );
        dispatch(fetchNowPlayingSuccess(uniqueMovies));
      }
    } catch (err) {
      dispatch(fetchNowPlayingError(err.message));
    } finally {
      dispatch(setMoviesLoader(false));
    }
  };
};

export const fetchMovieDetails = (payload) => {
  return function (dispatch) {
    dispatch(setMovieDetailsLoader(true));
    MovieService.GetMovieDetails(payload)
      .then((response) => {
        const { data } = response;
        if (data.status && data.Records.length > 0) {
          dispatch(fetchMovieDetailsSuccess(data.Records[0]));
        }
      })
      .catch((err) => {
        dispatch(fetchMovieDetailsError(err.message));
      });
  };
};

export const fetchShowTimes = ({
  date,
  cinema_id,
  movieFormats,
  md_id,
}) => async (dispatch) => {
  try {
    let newMovieTimesArray = [];
    dispatch(setShowTimesLoader(true));
    for (let i = 0; i < movieFormats.length; i++) {
      const { data } = await MovieService.GetShowTimes({
        date,
        md_id: movieFormats[i].md_id,
        cinema_id,
      });
      if (data && data.status) {
        dispatch(setShowTimesLoader(false));
      }
      if (data?.status && data?.Records?.length > 0) {
        newMovieTimesArray = [...newMovieTimesArray, ...data?.Records];
      }
    }
    // const { data } = await MovieService.GetShowTimes({
    //   date,
    //   md_id,
    //   cinema_id,
    // });
    // if (data && data.status) {
    //   dispatch(setShowTimesLoader(false));
    // }
    // if (data?.status && data?.Records?.length > 0) {
    //   newMovieTimesArray = [...newMovieTimesArray, ...data?.Records];
    // }
    dispatch(fetchShowTimeSuccess(newMovieTimesArray));
  } catch (err) {
    dispatch(fetchShowTimeError(err.message));
  }
};

export const fetchMovieDates = (payload) => {
  return function (dispatch) {
    dispatch({ type: FETCH_CURRENT_MOVIE_SHOW_DATES });
    dispatch(setCurrentShowLoader(true));

    // if (!payload.movie_id || !payload.cinema_id) {
    //   return;
    // }
    MovieService.GetMovieDates(payload)
      .then((response) => {
        const { data } = response;
        if (data.status && data.Records.length > 0) {
          const dates = [
            ...new Set(
              data.Records.map(
                (date) =>
                  (date = moment(date.ss_start_date).format("DD-MM-YYYY"))
              )
            ),
          ];

          dispatch(fetchCurrentMovieShowDates(dates));
        }
      })
      .catch((err) => {
        console.log(err.response ? err.response : err);
      });
  };
};

export const fetchMovieFormats = ({ movie_id }) => async (dispatch) => {
  try {
    dispatch({ type: SET_MOVIE_FORMATS, payload: [] });
    const { data } = await MovieService.GetMovieFilters({ movie_id });
    if (data?.status && data?.Records?.length > 0) {
      dispatch({ type: SET_MOVIE_FORMATS, payload: data.Records });
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchDateAllShows = ({
  selectedDate,
  cinema_id,
  rating_id,
  format_id,
}) => async (dispatch) => {
  try {
    let newMovieTimesArray = [];
    dispatch(setAllShowsLoading(true));
    const { data } = await MovieService.GetAppAllShows({
      cinema_id,
      rating_id,
      format_id,
    });
    console.log(data);
    if (data && data.status) {
    }
    if (data?.status && data?.Records?.length > 0) {
      // newMovieTimesArray = [...newMovieTimesArray, ...data?.Records];
      newMovieTimesArray = _.sortBy(
        _.toPairs(
          _.groupBy(data.Records, (x) =>
            moment(x.ss_actual_start_date).format("YYYY-MM-DD")
          )
        ),
        [(x) => x[0]]
      ).map((x) => ({
        date: x[0],
        shows: x[1],
      }));

      console.log(
        "🚀 ~ file: moviesActions.js ~ line 249 ~ newMovieTimesArray=_.sortBy ~ newMovieTimesArray",
        newMovieTimesArray
      );
    }
    dispatch({ type: FETCH_DATE_ALL_SHOWS, payload: newMovieTimesArray });
  } catch (err) {
    dispatch(fetchShowTimeError(err.message));
  } finally {
    dispatch(setAllShowsLoading(false));
  }
};
